@import url(https://fonts.googleapis.com/css?family=Anonymous+Pro);

.Charity-background{
  color: white!important;
  background:linear-gradient(to left,rgb(2, 2, 3),rgb(24, 65, 141),rgb(2, 3, 3));
  font-size: 1.2rem;
  border-radius: 5px!important;
  height:560px;
  overflow:hidden;
  text-shadow:2px 2px 0 black,
    1px 1px 0 #000,
    1px 1px 0 #000,
    1px 1px 0 #000,
    2px 1px 0 #000;
}

.rows {
  margin: 0px 0px;
  width:100%;
  height:430px;
}


.hydro-logo{
  border:1px solid rgb(255, 255, 255);
  border-radius: 50%;
}

.charity-navbar{
  display: inline-block;
  list-style-type: none;
  border-bottom:1px solid rgb(245, 245, 245);
  border-bottom-left-radius:2px;
  border-bottom-right-radius:2px;
  width:100%;
  padding:15px;
  text-transform: capitalize;
  text-align: center;
  font-weight: 700;
  -webkit-text-stroke:0.00001px #3433;
  text-shadow:2px 2px 0 black,
        1px 1px 0 #000,
        1px 1px 0 #000,
        1px 1px 0 #000,
        2px 1px 0 #000;
  }

.charity-navbar li{
  display: inline-block;
  color:rgb(243, 193, 77);
  cursor:pointer;
  text-align: center;
  text-transform: uppercase;
  transition: all .36s ease-in-out;
  font-size: 25px;
  
}

.charityFactory-navbar{
  list-style-type: none;
  border-bottom:1px solid rgb(245, 245, 245);
  border-bottom-left-radius:2px;
  border-bottom-right-radius:2px;
  width:100%;
  padding:15px;
  text-transform: capitalize;
  text-align: center;
  font-weight: 700;
  -webkit-text-stroke:0.00001px #3433;
  text-shadow:2px 2px 0 black,
        1px 1px 0 #000,
        1px 1px 0 #000,
        1px 1px 0 #000,
        2px 1px 0 #000;
  }

.charityFactory-navbar li{
  display: inline-block;
  color:rgb(252, 252, 252);
  cursor:pointer;
  text-align: center;
  text-transform: uppercase;
  transition: all .20s ease-in-out;
  font-size: 22px;
  
}

.charity-navbar li:hover, .charityFactory-navbar li:hover{
  color:rgb(243, 193, 77);
}


.charity-menu{
  display: inline-block;
  list-style-type: none;
  border-bottom-left-radius:2px;
  border-bottom-right-radius:2px;
  width:100%;
  padding:15px;
  text-transform: capitalize;
  text-align: center;
  font-weight: 700;
  }
  
.charity-menu li{
  display: inline-block;
  color:white;
  cursor:pointer;
  text-align: center;
  box-shadow:5px 10px 15px rgb(10, 10, 10);
  }
  
.charity-menu li:hover{
  cursor:pointer;
  color:rgb(243, 193, 77);
  box-shadow:2px 0px 5px rgb(10, 10, 10);
  }

.charityPage-columns{
  float: left;
  width: 25%;
  padding: 0 30px;
  margin-top:-5px;  
}

.charityInstance-Box{
  border:1px solid white;
  background:linear-gradient(30deg,rgb(2, 3, 3),rgb(24, 65, 141));
  border-radius:10px;
  height:30%;
  width:20%;
  font-size: 20px;
  margin: 2% 3%;
  padding:2% 1%;
  overflow: hidden;
  transition: all .36s ease-in-out;
  -webkit-text-stroke:0.00001px #3433;
  text-shadow:2px 2px 0 black,
        1px 1px 0 #000,
        1px 1px 0 #000,
        1px 1px 0 #000,
        2px 1px 0 #000;
}

.charityInstance-Box:hover{
  color:rgb(241, 217, 163);
  transform:scale(1.1);
  border: 1px solid rgb(228, 193, 112);
}

.fontIcon{
  font-size: 3rem;
}

.donationImg{
  width:100%;
  margin-top:20px;
  border-radius:48%;
  font-size: 8rem;
  color:rgb(243, 210, 103);
  -webkit-text-stroke:0.00001px #3433;
  text-shadow:2px 2px 0 black,
        1px 1px 0 #000,
        1px 1px 0 #000,
        1px 1px 0 #000,
        2px 1px 0 #000;
}


.donationOwner{
  color:rgba(139, 170, 199, 0);
  position:absolute;
  padding:10px;
  margin-top:-30px;
  margin-bottom:10px;
  transform:translateX(-0%);
  font-size: 0px;
  border-bottom:1px solid rgba(255, 255, 255, 0.664);
  border-bottom-right-radius: 15px;
  height:250px;
  width:0%;
  -webkit-text-stroke:0.00001px #3433;
  background:transparent;
  transition: transform 0.2s ease-out;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden; 
  z-index:-100;
}

.donationOwner.Open{
  color:rgb(139, 170, 199);
  position:absolute;
  padding:10px;
  margin-bottom:10px;
  transform:translateX(0%);
  font-size: 15px;
  border-bottom:1px solid rgba(255, 255, 255, 0.664);
  border-top:1px solid rgba(255, 255, 255, 0.664);
  border-bottom-right-radius: none;
  height:250px;
  width:20%;
  min-width:230px;
  -webkit-text-stroke:0.00001px #3433;
  background:linear-gradient(to bottom,rgb(2, 3, 3),rgb(4, 17, 41));
  transition: transform 0s ease-out;
  z-index:100;
  text-shadow:2px 2px 0 black,
        1px 1px 0 #000,
        1px 1px 0 #000,
        1px 1px 0 #000,
        2px 1px 0 #000;
}

.donatfionOwner.Open p{
  color:rgb(235, 189, 92);
  margin-bottom:0;
}

.ownerButton{
  color:rgb(235, 189, 92);
  position:absolute;
  margin-top:-30px;
  margin-bottom:10px;
  transform:translateX(0%);;
  border:1px solid rgba(255, 255, 255, 0.664);
  border-left:none;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  height:30px;
  width:120px;
  -webkit-text-stroke:0.00001px #3433;
  background:rgb(0, 0, 0);
  cursor:pointer;
  text-shadow:2px 2px 0 black,
        1px 1px 0 #000,
        1px 1px 0 #000,
        1px 1px 0 #000,
        2px 1px 0 #000;
  transition: transform 0.1s ease-out;
}

.ownerButton.Open{
  color:rgb(214, 24, 24);
  position:absolute;
  font-size: 20px;
  width:40px;
  margin-bottom:10px;
  transform:translateX(230px);
  transition: transform 0s ease-out;
}

.donationDescription{
  color:rgba(139, 170, 199, 0);
  position:absolute;
  padding:10px;
  margin-top:10px;
  margin-bottom:10px;
  transform:translateX(-0%);
  font-size: 0px;
  border-bottom:1px solid rgba(255, 255, 255, 0.664);
  border-bottom-right-radius: 10px;
  height:250px;
  width:0%;
  -webkit-text-stroke:0.00001px #3433;
  background:transparent;
  transition: transform 0.2s ease-out;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden; 
  z-index:-100;
}

.donationDescription.Open{
  color:rgb(139, 170, 199);
  position:absolute;
  padding:10px;
  margin-bottom:10px;
  transform:translateX(0%);
  font-size: 15px;
  border-bottom:1px solid rgba(255, 255, 255, 0.664);
  border-top:1px solid rgba(255, 255, 255, 0.664);
  border-bottom-right-radius: none;
  height:250px;
  width:20%;
  min-width:230px;
  -webkit-text-stroke:0.00001px #3433;
  background:linear-gradient(to bottom,rgb(2, 3, 3),rgb(4, 17, 41));
  transition: transform 0s ease-out;
  z-index:100;
  text-shadow:2px 2px 0 black,
        1px 1px 0 #000,
        1px 1px 0 #000,
        1px 1px 0 #000,
        2px 1px 0 #000;
}

.descriptionButtonMargined{
  color:rgb(235, 189, 92);
  position:absolute;
  margin-top:230px;
  margin-bottom:10px;
  transform:translateX(0%);;
  border:1px solid rgba(255, 255, 255, 0.664);
  border-left:none;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  height:30px;
  width:120px;
  -webkit-text-stroke:0.00001px #3433;
  background:rgba(2, 3, 3, 0.753);
  cursor:pointer;
  text-shadow:2px 2px 0 black,
        1px 1px 0 #000,
        1px 1px 0 #000,
        1px 1px 0 #000,
        2px 1px 0 #000;
  transition: transform 0.1s ease-out;
}

.descriptionButton{
  color:rgb(235, 189, 92);
  position:absolute;
  margin-top:10px;
  margin-bottom:10px;
  transform:translateX(0%);;
  border:1px solid rgba(255, 255, 255, 0.664);
  border-left:none;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  height:30px;
  width:120px;
  -webkit-text-stroke:0.00001px #3433;
  background:rgb(0, 0, 0);
  cursor:pointer;
  text-shadow:2px 2px 0 black,
        1px 1px 0 #000,
        1px 1px 0 #000,
        1px 1px 0 #000,
        2px 1px 0 #000;
  transition: transform 0.1s ease-out;
}

.descriptionButton.Open{
  color:rgb(214, 24, 24);
  position:absolute;
  font-size: 20px;
  width:40px;
  margin-bottom:10px;
  transform:translateX(230px);
  transition: transform 0s ease-out;
}

.donationDescription p{
 font-weight: 1000;
 font-size: 17px;
 margin-block-end:0px;
}

.donationContribute{
  color:rgba(139, 170, 199, 0);
  position:absolute;
  padding:10px;
  margin-top:50px;
  margin-bottom:10px;
  transform:translateX(0%);
  font-size: 0px;
  border-bottom:1px solid rgba(255, 255, 255, 0.664);
  border-bottom-right-radius: 15px;
  height:250px;
  width:0%;
  -webkit-text-stroke:0.00001px #3433;
  background:transparent;
  transition: transform 0.2s ease-out;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden; 
  
  z-index:-100;
}

.donationContribute.Open{
  color:rgb(139, 170, 199);
  position:absolute;
  padding:10px;
  margin-bottom:10px;
  transform:translateX(0%);
  font-size: 15px;
  border-bottom:1px solid rgba(255, 255, 255, 0.664);
  border-top:1px solid rgba(255, 255, 255, 0.664);
  border-bottom-right-radius: none;
  height:250px;
  width:20%;
  min-width:230px;
  -webkit-text-stroke:0.00001px #3433;
  background:linear-gradient(to bottom,rgb(2, 3, 3),rgb(4, 17, 41));
  transition: transform 0s ease-out;
  z-index:100;
  text-shadow:2px 2px 0 black,
        1px 1px 0 #000,
        1px 1px 0 #000,
        1px 1px 0 #000,
        2px 1px 0 #000;
}

.contributeButton{
  color:rgb(235, 189, 92);
  position:absolute;
  margin-top:50px;
  margin-bottom:10px;
  transform:translateX(0%);;
  border:1px solid rgba(255, 255, 255, 0.664);
  border-left:none;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  height:30px;
  width:120px;
  -webkit-text-stroke:0.00001px #3433;
  transition: transform 0.1s ease-out;
  background:rgb(0, 0, 0);
  cursor:pointer;
  text-shadow:2px 2px 0 black,
        1px 1px 0 #000,
        1px 1px 0 #000,
        1px 1px 0 #000,
        2px 1px 0 #000;
  
}

.contributeButton.Open{
  color:rgb(214, 24, 24);
  position:absolute;
  font-size: 20px;
  width:40px;
  margin-bottom:10px;
  transform:translateX(230px);
  transition: transform 0s ease-out;
}

.contributeButtonMargined{
  color:rgb(235, 189, 92);
  position:absolute;
  margin-top:270px;
  margin-bottom:10px;
  transform:translateX(0%);;
  border:1px solid rgba(255, 255, 255, 0.664);
  border-left:none;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  height:30px;
  width:120px;
  -webkit-text-stroke:0.00001px #3433;
  background:rgba(2, 3, 3, 0.753);
  cursor:pointer;
  text-shadow:2px 2px 0 black,
        1px 1px 0 #000,
        1px 1px 0 #000,
        1px 1px 0 #000,
        2px 1px 0 #000;
  transition: transform 0.1s ease-out;
}

.backButton{
  color:rgb(235, 189, 92);
  position:absolute;
  margin-top:90px;
  margin-bottom:10px;
  transform:translateX(0%);;
  border:1px solid rgba(255, 255, 255, 0.664);
  border-left:none;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  height:30px;
  width:120px;
  -webkit-text-stroke:0.00001px #3433;
  transition: transform 0.1s ease-out;
  background:rgb(0, 0, 0);
  cursor:pointer;
  text-shadow:2px 2px 0 black,
        1px 1px 0 #000,
        1px 1px 0 #000,
        1px 1px 0 #000,
        2px 1px 0 #000;
  
}

.backButtonMargined-1{
  color:rgb(235, 189, 92);
  position:absolute;
  margin-top:310px;
  margin-bottom:10px;
  transform:translateX(0%);;
  border:1px solid rgba(255, 255, 255, 0.664);
  border-left:none;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  height:30px;
  width:120px;
  -webkit-text-stroke:0.00001px #3433;
  background:rgba(2, 3, 3, 0.753);
  cursor:pointer;
  text-shadow:2px 2px 0 black,
        1px 1px 0 #000,
        1px 1px 0 #000,
        1px 1px 0 #000,
        2px 1px 0 #000;
  transition: transform 0.1s ease-out;
}

.backButtonMargined-2{
  color:rgb(235, 189, 92);
  position:absolute;
  margin-top:310px;
  margin-bottom:10px;
  transform:translateX(0%);;
  border:1px solid rgba(255, 255, 255, 0.664);
  border-left:none;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  height:30px;
  width:120px;
  -webkit-text-stroke:0.00001px #3433;
  background:rgba(2, 3, 3, 0.753);
  cursor:pointer;
  text-shadow:2px 2px 0 black,
        1px 1px 0 #000,
        1px 1px 0 #000,
        1px 1px 0 #000,
        2px 1px 0 #000;
  transition: transform 0.1s ease-out;
}

.descriptionButton :hover, 
.contributeButton :hover,.contributeButtonMargined :hover,
.backButton :hover,.backButtonMargined-1 :hover,.backButtonMargined-2 :hover{
  color:rgb(224, 224, 224);
  }


.donationFunded{
  font-weight: 1000;
  font-size: 16px;
  margin-block-end:0px;
  padding:5px;
  margin-top:20px;
  margin-bottom:10px;
  margin-left:25%;
  border-top:1px solid white;
  border-bottom:1px solid white;
  border-radius: 15px;
  width:50%;
  background:linear-gradient(30deg,rgb(2, 3, 3),rgb(24, 65, 141));
  
 }

 .doughtnutWrapper{
  margin-top:30px;
   height:310px;
   width:300px;
   color:rgb(16, 197, 221);
   color:rgb(209, 235, 238);
 }

 .input-group-hydro {
  display: flex;
  align-items: center;
  padding: 0.3rem 1em;
  min-width:50px;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  background-color: #061b2f;
  border: 1px solid rgb(110, 110, 112);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.contributeInput{    
  width:100%;
  padding:5px;
  color:rgb(4, 3, 27);
  color:rgb(1, 1, 7);
  font-weight: 700;
  background:#e6e7eb;
  border-top-right-radius:5px;
  border-bottom-right-radius:5px;
  border:2px solid  rgb(133, 133, 134);
  border-left:none;     
  text-transform: capitalize;
}

.contributeInput :active,.contributeInput:focus{
  outline:none!important;
  color:rgb(1, 1, 7);
}

.progress{
  position:relative;
  width:100%;
  height:2.7em;
  background:transparent;
  border-radius:1.4em;
  color:white;
  align-items:center;
}

.progress-inner{
  align-items:center;
  position:absolute;
  margin-left:0;
  top: .4em;
  bottom:.4em;
  min-width:1rem;
  max-width:99%;
  background: linear-gradient(to right, black, #e9b148,black);  
  border-radius:1em;
  padding:1em;
  z-index:1;
  border:1px solid rgb(233, 233, 233);
  border-right:0;
  filter: blur(1px);
}

.progress-outer{
  align-items:center;
  position:absolute;
  margin-left:0;
  top: .4em;
  bottom:.4em;
  min-width:1rem;
  max-width:99%;
  background: linear-gradient(to left, #0f151b,rgb(35, 36, 48)); 
  border-radius:1em;
  border:1px solid #858ca3;
  padding:1em;
   
}

.progress p{
  margin-top:1%;
  margin-left:47%;
  font-size:.9rem;
  font-weight: 700;
  color:rgb(255, 255, 255);
  z-index:10;
  font-family: 'Lato', sans-serif;
}


.charityCard{
  color:rgb(245, 242, 237);
  border: 1px solid rgba(202, 202, 202, 0.993);
  border-radius:5px;
  font-size:20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
  text-align: center;
  margin-top:20px;
  margin-bottom:15px;
  overflow:hidden;
  height:185px!important;
  max-height:215px!important;
  cursor:pointer;
  background:linear-gradient(to bottom,#083863,rgb(0, 0, 5));
  box-shadow:5px 10px 15px rgb(10, 10, 10);
}

.charityCard:hover{
  border: 1px solid rgba(192, 154, 48, 0.993);
}

.charity-card-header{
  margin-top:15px;
  margin-left:15px;
  margin-right:15px;
  min-height:30px;
  max-height:70px;
  font-size:18px;
  color:rgb(255, 255, 255)!important;
  background:rgba(255, 255, 255, 0.137);
  text-align:center;
  overflow:hidden;
  border: 1px solid rgb(61, 61, 61);
  border-radius: 10px!important;
  border-bottom: none;
  text-shadow:0px 0px 0 black,
        1px 1px 0 #000,
        1px 1px 0 #000,
        1px 1px 0 #000,
        2px 1px 0 #000;
}

.charity-card-header strong {
  margin-bottom: 0.5rem;
  font-weight: 1000;
  line-height: 1.5;
}
.list-group-charity {
  position: relative;
  display: block;
  padding: 0.35rem 1.25rem 1.25rem;
  margin-bottom: -1px;
  background-color:transparent;
  border-top: 1px solid rgba(92, 92, 92, 0.342);
  letter-spacing: 1px;
  font-weight: 500;
  color:rgb(252, 252, 252);
}

.charity-deadline{
  margin-bottom:10px;
  color:rgb(243, 243, 243);
  font-size: 15px;
  font-family: 'Anonymous Pro', monospace;
  }

.charity-deadline-close{
  margin-bottom:10px;
  color:rgb(238, 88, 42);
  font-size: 15px;
  font-family: 'Anonymous Pro', monospace;
  }

.charity-awaiting-approval{
  margin-bottom:10px;
  color:rgb(241, 186, 66);
  font-size: 15px;
  font-family: 'Anonymous Pro', monospace;
  }

.charityProfile{
  border:1px solid rgb(172, 137, 41);
  background:rgb(5, 13, 43);
  color:rgb(253, 253, 253);
  border-radius:10px;
  width:70%;
  justify-content: center!important;
  align-items: center!important;
  padding:17px;
  font-size:16px!important;
  overflow:hidden;
  height:370px;
  overflow-y: scroll;
  padding:40px;
  padding-bottom:20px;
}

.charityLogo{
  border-radius:7px;
  box-shadow:5px 0 8px rgb(10, 10, 10);
  padding: 4px;
  background:linear-gradient(40deg,rgb(87, 8, 139),rgb(9, 189, 180));
  
}

.input-group-withdraw {
  display: flex;
  align-items: center;
  padding: 0.3rem 1em;
  min-width:50px;
  width:50px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  color: #eceff1;
  text-align: center;
  white-space: nowrap;
  background:linear-gradient(40deg,rgb(87, 8, 139),rgb(9, 189, 180));
  border: 1px solid rgb(7, 7, 7);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  letter-spacing: .5px;
  -webkit-text-stroke:0.00001px #3433;
   text-shadow:2px 2px 0 black,
            1px 1px 0 #000,
            1px 1px 0 #000,
            1px 1px 0 #000,
            2px 1px 0 #000;
}

.withdrawLabel{
  font-size: 10px;
  font-weight:700;
  color:#cea430; 
  
}

.input-group-charity {
  display: flex;
  align-items: center;
  padding: 0.3rem 1em;
  min-width:130px;
  margin-bottom: 0;
  font-size: 1em;
  font-weight: 700;
  line-height: 1.5;
  color: #eceff1;
  text-align: center;
  white-space: nowrap;
  background:linear-gradient(40deg,rgb(87, 8, 139),rgb(9, 189, 180));
  border: 1px solid rgb(7, 7, 7);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  letter-spacing: .5px;
  -webkit-text-stroke:0.00001px #3433;
   text-shadow:2px 2px 0 black,
            1px 1px 0 #000,
            1px 1px 0 #000,
            1px 1px 0 #000,
            2px 1px 0 #000;
}

.newCharityLabel{
  font-weight:700;
  color:#cea430; 
  
}

/* width */
.charityProfile::-webkit-scrollbar,
.registerAsContributor-card::-webkit-scrollbar,
.table-wrapper::-webkit-scrollbar,
.donationDescription.Open::-webkit-scrollbar{
  width: 5px;
}

/* Track */
.charityProfile::-webkit-scrollbar-track, 
.registerAsContributor-card::-webkit-scrollbar-track,
.table-wrapper::-webkit-scrollbar-track,
.donationDescription.Open::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgb(20, 20, 17);
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
  background:transparent;
  
}

/* Handle */
.charityProfile::-webkit-scrollbar-thumb, 
.registerAsContributor-card::-webkit-scrollbar-thumb,
.table-wrapper::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, rgb(4, 7, 27),rgb(207, 180, 81), rgb(5, 7, 26));
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
}

.donationDescription.Open::-webkit-scrollbar-thumb{
  background: linear-gradient(to bottom, rgba(4, 7, 27, 0), rgba(214, 211, 211, 0.664));
  border-bottom-right-radius: 35px;
}

.registrationWrapper-charity{
  display:flex;
  margin-top:10px;
 

}

.registrationWrapper-charity p{
  margin-bottom:10px;
}

.registerAsContributor-card{

    border:2px solid #cea430;
    background:rgb(5, 13, 43);
    color:rgb(255, 255, 255);
    margin:20px;
    border-radius:7px;
    height:370px;
    max-height:370px;
    width:50%;
    justify-content: center;
    align-items: center;
    padding:17px;
    font-size:16px;
    overflow-y:scroll;


}

.registerAsContributor-card2{

  border:2px solid #cea430;
  background:rgb(5, 13, 43);
  color:rgb(255, 255, 255);
  margin:20px;
  border-radius:7px;
  height:370px;
  max-height:370px;
  width:50%;
  justify-content: center;
  align-items: center;
  padding:17px;
  font-size:16px;
  letter-spacing: .5px;
  overflow: hidden;

}

.registerIcon{
  font-size: 5rem;
  color:#ffffff;
  background:linear-gradient(40deg,rgb(134, 13, 214),rgb(11, 255, 243));
  width:150px;
  margin-bottom:50px;
  margin-left:35%;
  border-radius: 50%;
  border: 1px solid #cea430;
}


.registeredEIN{
  background:linear-gradient(40deg,rgb(134, 13, 214),rgb(11, 255, 243));
  border: 1px solid rgb(226, 175, 79);
  border-radius: 10px;
  margin:50px;
}

.chadrityProfile{
  border:1px solid #cea430;
  background:rgb(5, 13, 43);
  color:rgb(253, 253, 253);
  margin:20px;
  border-radius:7px;
  width:60%;
  justify-content: center!important;
  align-items: center!important;
  padding:17px;
  font-size:16px!important;
  overflow:hidden;
  height:390px;
}

.Approved{
  color:rgb(24, 223, 24);
 
}

.Awaiting{
  color:rgb(235, 178, 54);
  
}

.Disabled{
  color:rgb(247, 25, 25);

}


.table-wrapper{
  margin-top:-10px;
  padding-top:5px;
  background: transparent;
  font-size:0.9rem;
  border-top: none;
  text-align:center;
  overflow-y: scroll;
  height:370px;
  max-height:370px;
  color:rgb(216, 216, 216);
  margin-left:5px;
}

.table-size {
  text-align:center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5rem;
  padding-right: 5rem;
  width:100%;
  min-width:1000px;
  border-bottom:1px solid rgb(46, 46, 46);
  border:1px solid rgba(248, 248, 248, 0.329);

}

.list-header{
  padding-top:5px;
  padding-bottom:5px;
  text-align:center;
  border-bottom:1px solid rgba(248, 248, 248, 0.329); 
}

.table-size th{
  padding-bottom:5px; 
  
}

.table-size tr{
  border-bottom:1px solid rgba(241, 238, 238, 0.329); 
  padding-bottom:5px; 
  font-size: 16px;
}

.table-size tr:hover{
  background:linear-gradient(40deg,rgba(98, 10, 156, 0.39),rgba(15, 241, 211, 0.144),rgba(15, 177, 241, 0.308),rgba(15, 38, 241, 0.096));
  cursor: pointer;
}

.list-img{
  height:30px;
  border-radius:10px;
  width:40px;
  border:1px solid white;
}

.cursor-pointer a{
  color:rgb(255, 255, 255);
}

.cursor-pointer a:hover{
  color:rgb(231, 184, 81);
  text-decoration: none;
}





