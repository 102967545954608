/* @define dapp-preview */

.dapp-preview {
  border: 0;
  min-width: 245px;
}

.dapp-preview__body {
  background: rgb(7, 34, 59) !important;
  color: #2ac0d5;
  padding-left: 0;
  padding-right: 0;
}

.dapp-preview__title {
  color: #b2f0f9 !important;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
}

.dapp-preview__category {
  font-size: 13px;
  color: #2f5d71;
}

.dapp-preview__purchases {
  width: 150px;
  line-height: 15px;
  font-weight: 200;
  padding-top: 15px;
  position: absolute;
  left: 100px;
  font-size: 13px;
}

.dapp-preview__body button {
  text-transform: uppercase;
  border-radius: 20px;
  width: 75px;
  font-size: 13px;
  border: none;
  margin-right: 15px;
}

.dapp-preview__body .btn-success {
  background-color: #0b3257!important;
  border: 1px solid transparent;
  color: #fff;
}

.dapp-preview__body .btn-danger {
  border: 1px solid transparent;
  background-color: #784083!important;
  color: #fff;
}

.dapp-preview__body .btn-success:hover {
  border: 1px solid transparent;
  color: #fff;
}

.dapp-preview__body .btn-danger:hover {
  background-color: hsl(290, 34%, 23%)!important;
  border: 1px solid transparent;
  color: #fff;
}

.dapp-preview .btn-outlined {
  background: hsl(207, 78%, 18%);
  padding: 5px;
  border: none;
  color: hsl(194, 38%, 63%);
}

.dapp-preview .btn-outlined:hover {
  border: none;
  background-color: #0b2750 !important;
  color: #7cefff;
}

.dapp-preview__body button:hover {
  border: none;
  background-color: #031838 !important;
  color: #7cf0ff80;
}

.dapp-preview__overlay-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.card-deck .card {
  // margin-right: -5px;
  background: none !important;
}

.dapp-preview__image {
  border-radius: 5px;
  width: 223px;
  height: 175px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  box-shadow: 5px 5px 12px rgba(57, 63, 72, 0.1);
  background-color: #fff;
}

.dapp-preview__image:hover {
  box-shadow: 5px 5px 12px rgba(57, 63, 72, 0.2);
}

.dapp-preview__overlay-wrapper {
  margin: 0 auto !important;
  text-align: center;
}

.dapp-preview__overlay-wrapper:hover {
  width: 100%;
  height: 100%;
  background-color: #000;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 5px 5px 12px rgba(57, 63, 72, 0.2);
  transition: 0.5s;
  background: -moz-linear-gradient(
    top,
    rgba(137, 255, 241, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(50%, rgba(137, 255, 241, 0)),
    color-stop(100%, rgba(0, 0, 0, 1))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(137, 255, 241, 0) 50%,
    rgba(0, 0, 0, 1) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(137, 255, 241, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    rgba(137, 255, 241, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) -44%,
    black 30%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0089fff1', endColorstr='#333',GradientType=0 );
  opacity: 1;
  background-clip: content-box, border-box;
}

.dapp-preview__overlay-wrapper button {
  visibility: hidden;
  padding: 5px 25px 5px 25px;
  font-size: 15px;
  background-color: #fff;
  color: #333;
  font-family: "Nunito-SemiBold", san-serif;
}

.dapp-preview__overlay-wrapper:hover button {
  visibility: visible;
  text-align: center;
  margin: 0 auto;
  display: inline-block;
  margin-top: 75px;
  margin-left: 0;
  margin-right: 0;
}
