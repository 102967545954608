/* @define sidebar */

.sidebar {
  background-color: rgb(6, 27, 47) !important;
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  top: 0;
  bottom: -60px;
  width: 100%;
  padding-top: 30px;
}

.sidebar__link {
  color: #2ac0d5;
  font-size: 17px;
  padding: 10px 0 10px 30px;
  margin-bottom: 10px;
  margin-right: 15px;
}

.sidebar__link svg {
  font-size: 25px;
}

.sidebar__link:hover {
  background-color: #142d44;
  color: #fff;
  cursor: pointer;
  list-style-type: none;
  padding: 10px 0 10px 30px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  margin-bottom: 10px;
}

.sidebar__link--active {
  background-color: $snow-classic-blue;
  color: $snow-white;
  list-style-type: none;
  padding: 10px 0 10px 30px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  margin-bottom: 10px;
}

.sidebar__link--active .sidebar__badge {
  background-color: #0e1f50;
}

.sidebar__link--active:hover {
  color: $snow-white;
  background-color: $snow-classic-blue;
}

.sidebar__icon {
  margin-right: 10px;
  font-size: 1.35rem;
}

.sidebar__badge {
  background-color: #2f4562;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  float: right;
  padding: 6px 10px 6px 10px;
  margin-top: -2px;
  margin-right: 8px;
}

.sidebar__hydro-drop {
  width: 15px;
  margin-right: -5px;
  margin-top: -2px;
  margin-left: 5px;
}

.sidebar .onboardingButton {
  margin-left: 30px;
  margin-bottom: 15px;
}
