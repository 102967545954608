/* @define remove */

.remove__body {
  padding: 1.75rem 2.25rem 2.25rem 2.25rem;
}

.remove__header {
  padding: 1.75rem 2.25rem 0 2.25rem;
}

.remove__icon {
  color: #ec4141;
  font-size: 3.2rem;
  margin-right: 15px;
}

.remove__title {
  font-family: "Nunito-Bold", sans-serif;
  color: #ec4141;
  font-size: 17px;
}

.remove__close-icon {
  color: #1f4eff;
  font-size: 2rem;
  cursor: pointer;
}

.remove__preview {
  background-color: #f2f4ff;
  border-color: #d4dbff;
  color: #333;
  padding: 0.25rem;
}

.remove__logo {
  height: 60px;
  margin-right: 10px;
}

.modal-content .remove__body p {
  font-family: "Nunito-Regular", sans-serif;
  color: #636363;
  font-size: 15px;
}
