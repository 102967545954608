



.OxideStats {

  height: 10vh;

}

.OxideWager {

  height: 20vh;


}

.IconPhoto {

  font-size: 30vw;
  color: #0971f5;

}

.OxideLegend {
  color: #0971f5;
}

.OxideButton {

  height: 10vh;

}


.MuiTableCell-body {
  color: #fff !important;
}