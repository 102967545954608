/* @define header */

.navbar {
  margin: 0;
  height: 85px auto;
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 2rem;
  background-color: rgb(6, 27, 47) !important;
}

.navbar-brand {
  padding-left: 15px;
}

.header__title {
  color: #333;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: -8px;
  text-align: right;
}

.header__logo {
  width: 100px;
  margin-top: -20px;
}

.header__subtitle {
  font-size: 12px;
  font-weight: 600;
  color: #2ac0d5;
  padding: 5px 0 0 0px;
}

.header__hydro {
  color: $primary;
}

.fixed-nav {
  position: fixed;
  top: 0;
}

.navbar-light .navbar-toggler {
  color: #2ac0d5;
}

.navbar .onboardingButton {
  margin-right: 30px;
}

.header__version {
  font-size: 10px;
  color: #2ac0d5;
  position: absolute;
  top: 13px;
  left: 140px;
}

.navbar-light .navbar-nav .nav-link {
  color: #2ac0d5 !important;
}

// .navbar-collapse {
//     /* flex-basis: 100%; */
//     flex-grow: 1;
//     /* align-items: center; */
//     background: black;
//     color: white;
//     display: flex;
//     /* align-items: center; */
//     /* width: 100%; */
//     padding: 1rem;
//     margin: 1rem;
//     padding-bottom: 10rem;
//     /* align-content: center; */
// }
