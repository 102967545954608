/* @define purchase */

.purchase__body {
  padding: 1.75rem 2.25rem 2.25rem 2.25rem;
}

.purchase__header {
  padding: 1.75rem 2.25rem 0 2.25rem;
}

.purchase__icon {
  color: #88b0de!important;
  font-size: 3.2rem;
  margin-right: 15px;
}

.purchase__title {
  font-family: "Nunito-Bold", sans-serif;
  color: #88b0de!important;
  font-size: 17px;
}

.purchase__close-icon {
  color: #88b0de!important;
  font-size: 2rem;
  cursor: pointer;
}

.page-link {
  border: 1px solid transparent !important;
}


.page-number:active{
  color: blue;
}

.purchase__preview {
  background-color:hsl(209, 54%, 16%)!important;
  border-color: transparent;
  color:  #88b0de!important;
  padding: 0.25rem;
}

.purchase__logo {
  height: 60px;
  margin-right: 10px;
}

.purchase__price {
  color: #88b0de!important;
  margin: 0;
  font-size: 600;
}

.purchase__hydro-icon {
  margin-top: -3px;
  margin-right: 5px;
  margin-left: 3px;
}

.modal-content .purchase__body p {
  font-family: "Nunito-Regular", sans-serif;
  color: #88b0de!important;
  font-size: 15px;
}

.purchase__small-price {
  opacity: 0.7;
  font-size: 10px;
  margin-bottom: 0;
}

.btn-primary {
  background-color: #11385c !important;
  color: #88b0de;
  border-color: transparent;
}
