/* @define qa */

.qa {
  padding-top: 5px;
  padding-bottom: 5px;
}

.qa__header {
  border-radius: 5px;
  cursor: pointer;
}

.qa__question {
  padding: 2;
  padding-left: 18px;
  color: #fff;
  text-decoration: none;
}

.qa__question :hover {
  text-decoration: none;
  color: #fff;
}

.qa__answer {
  color: #fff;
}
