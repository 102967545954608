/* @define transaction-button-modal */

.transaction-button-modal__success-icon {
  font-size: 3.5rem;
  color: #2fd357;
}

.transaction-button-modal__subtitle {
  color: #9c9c9c;
  font-size: 15px;
}

.transaction-button-modal__error-icon {
  font-size: 3.5rem;
  color: red;
}

.transaction-button-modal__title {
  font-size: 1rem;
  font-weight: 600;
}
