/* @define transaction */

.transaction {
  background-color: rgb(11, 50, 87) !important;
  border: none;
}

.transaction__header {
  display: -webkit-box;
  color: white;
}

.transaction__header svg {
  margin-top: -5px;
}

.transaction__type {
  font-family: "Nunito-SemiBold", sans-serif;
  font-size: 16px;
  color: #fff;
  padding-left: 10px;
  margin: 0;
}

.transaction__icon {
  font-size: 1.5rem;
  font-weight: 600;
}

.transaction__date {
  font-family: "Nunito-Regular", sans-serif;
  font-size: 12px;
  color: #fff;
  opacity: 0.7;
  margin: 0;
}

.transaction__amount {
  font-family: "Nunito-SemiBold", sans-serif;
  font-size: 20px;
  margin: 0;
  text-align: right;
}

.transaction__amount--withdrawal {
  color: red;
}
