/* @define header-account */

.header-account__wrapper {
  text-align: right;
  margin-right: 35px;
  cursor: pointer;
}

.header-account__welcome {
  font-family: "Nunito-Bold", sans-serif;
  color: #fff;
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 0;
  margin-left: 30px;
}

.header-account__ein {
  font-family: "Nunito-Bold", sans-serif;
  font-size: 13px;
  font-weight: 300;
  color: #fff;
  text-transform: uppercase;
}

.header-account__identicon {
  margin-right: 30px;
}
