/* @define submit */

.submit_card {
  max-width: 300px;
  margin-top: 15px;
  margin-bottom: 0;
  margin-right: 15px;
  background-color: rgb(11, 50, 87) !important;
  color: white !important;
  border-radius: 10px;
  display: inline-block;
}

.submit_card .card-header {
  color: #88b0de!important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.submit_card .card-footer {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.submit_card .card-body {
  text-align: center;
  min-height: 230px;
  font-size: 14px;
}

.submit_card .card-footer {
  text-align: center;
  padding: 15px;
}

.submit_card button {
  background-color: #1d4cff;
  border: 1px solid transparent;
  color: #fff;
}

.submitDapp p {
  /* padding-bottom: 20px; */

}

.submitDapp h1 {
  margin-bottom: 10px;

}

.submitDapp h3 {
  font-size: 20px;
  margin-top: 25px;
  background-color: rgb(11, 50, 87) !important;

  padding: 20px;
  border-radius: 5px;
}

// button.partner {
//   background-color: #3ad15c;
//   border-color: #3ad15c;
// }

// button.resource {
//   background-color: #1f4dfe;
//   border-color: #1f4dfe;
// }

// button.smart_contract {
//   background-color: #262626;
//   border-color: #262626;
// }

// button.snippet {
//   background-color: #ff6434;
//   border-color: #ff6434;
// }

// button.become_partner {
//   background-color: #238639;
//   border-color: #238639;
// }
