/**
 * Overriding Bootstrap variables
 */
$primary: #1d4cff;
$success: #2fd357;

$input-btn-padding-y: 0.4rem;
$input-btn-padding-x: 3rem;

$btn-border-radius: 2rem;
$btn-border-radius-lg: 2rem;
$btn-border-radius-sm: 2rem;

$card-border-width: 0;

$popover-border-width: 0;
$popover-body-color: #616161;
$popover-body-padding-y: 1.2rem;
$popover-body-padding-x: 1.2rem;

/**
 * Custom variables
 */
$snow-classic-blue: #143957;
$snow-focused-blue: #1739bb;
$snow-white: #fff;
$snow-light-gray: #f9f9f9;
$snow-light-dark: #212529;

$snow-light-blue: #d6dbfd;

$snow-footer-grey: #8b8b8b;
$snow-grey-dark: #616161;
