

  .txButton{
   color:rgb(238, 237, 237)!important;
   background:#083e85!important;
   background:linear-gradient(40deg,rgb(134, 13, 214),rgb(18, 207, 198))!important;
   height:34px!important;
   border-radius: 5px!important;
   border:1px solid rgb(179, 133, 66)!important;
   overflow:hidden!important;
   font-size: 0.875rem!important;
   min-width: 100px!important;
   transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
   border-radius:7px!important;
   margin-top:-5px!important;
   font-weight:600!important;
   outline:none!important;
   text-shadow:1px 2px 0 black,
   1px 1px 0 #000,
   1px 1px 0 #000,
   1px 1px 0 #000,
   2px 1px 0 #000;
   
 }


 .txButton:hover{
   background:linear-gradient(40deg,rgb(128, 14, 204),rgb(13, 185, 177))!important;
   color:rgb(245, 245, 245)!important;
   border:1px solid rgb(245, 191, 76)!important;
}

.txButton-pending{
   color:#0971f5!important;
   background:#fff!important;
   margin-top:-5px!important;
   border-radius: 5px!important;
   border:1px solid white!important;
   overflow:hidden!important;
   font-size: 0.875rem!important;
   min-width: 64px!important;
   transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
   border-radius:7px!important;
   margin-top:12px!important;
   outline:none!important;
   
 }

 .txButton-pending:hover{
   background: rgb(197, 196, 196)!important;
   color:#0b64da!important;
}

.txButton-error{
   color:rgb(233, 233, 233)!important;
   background:#f44336!important;
   margin-top:-5px!important;
   border-radius: 5px!important;
   border:1px solid white!important;
   overflow:hidden!important;
   font-size: 0.875rem!important;
   min-width: 64px!important;
   transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
   border-radius:7px!important;
   margin-top:12px!important;
   outline:none!important;
 }

 .txButton-error:hover{
   background:#b43229!important;
   color:rgb(179, 180, 180)!important;
}

.txDisabled{
   color:rgba(238, 237, 237, 0.548)!important;
   background:#083e85!important;
   background:linear-gradient(40deg,rgba(134, 13, 214, 0.479),rgba(18, 207, 198, 0.466))!important;
   height:34px!important;
   border-radius: 5px!important;
   border:1px solid rgb(179, 133, 66)!important;
   overflow:hidden!important;
   font-size: 0.875rem!important;
   min-width: 100px!important;
   transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
   border-radius:7px!important;
   margin-top:-5px!important;
   font-weight:600!important;
   outline:none!important;
   text-shadow:1px 2px 0 black,
   1px 1px 0 #000,
   1px 1px 0 #000,
   1px 1px 0 #000,
   2px 1px 0 #000;
   
 }