/* @define call-to-action */

.call-to-action {
  background-color: rgb(11, 50, 87) !important;
  padding: 100px 30px 100px 30px;
}

.call-to-action__title {
  font-family: "Nunito", sans-serif;
  font-size: 33.63px;
  color: #fff;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

.call-to-action__subtitle {
  font-family: "Nunito-Regular", sans-serif;
  font-size: 27.12px;
  color: #fff;
  margin-bottom: 30px;
  line-height: 1.2;
}
